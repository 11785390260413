<template>
    <div class="flex mx-5 my-5">
        <SideBar :btnText="'+ 新增推廣大圖'" @clickBtn="addBanner" />
        <div class="pb-5 flex-1">
            <div class="shadow-lg bg-white rounded-lg ml-3 pb-5 relative">
                <LoadingComponent :isLoading="loading" />
                <div class="overflow-x-auto">
                    <table class="myTable">
                        <thead>
                            <tr>
                                <th>暱稱</th>
                                <th>手機</th>
                                <th>啟用狀態</th>
                                <th>使用期間</th>
                                <th v-permission="['update']">編輯</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in diaplayData" :key="index" class="text-center duration-200 transition">
                                <td class="show-ellipsis">{{ item.name }}</td>
                                <td>{{ item.phone }}</td>
                                <td>
                                    {{ item.activated === "1" ? "啟用" : "停用" }}
                                </td>
                                <td>
                                    <span v-if="item.duration">
                                        {{ item.duration.start | formatDate }} -
                                        {{ item.duration.end | formatDate }}
                                    </span>
                                    <span v-else> 不限使用期間 </span>
                                </td>
                                <td v-permission="['update']" class="cursor-pointer" @click="goEdit(item.banana_id)">
                                    <i class="far fa-edit"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <AddPhoneDialog v-model="showDialog" :datas="listData" @getData="getData" />
    </div>
</template>

<script>
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
import SideBar from "../components/SideBar.vue";
import AddPhoneDialog from "../components/AddPhoneDialog.vue";

export default {
    name: "newComer",
    components: {
        LoadingComponent,
        SideBar,
        AddPhoneDialog,
    },
    computed: {
        diaplayData() {
            const result = [];
            if (!this.datas[1]) {
                return [];
            }
            this.datas[1].providers.forEach((i) => {
                result.push(i);
            });
            return result;
        },
        listData() {
            if (this.datas[1]) {
                return this.datas[1].providers;
            }
            return [];
        },
    },
    data() {
        return {
            datas: {},
            loading: false,
            showDialog: false,
        };
    },
    methods: {
        // 新增
        addBanner() {
            this.showDialog = true;
        },
        // 編輯
        goEdit(val) {
            this.$router.push({
                path: `/homepage_setting/promote_img/edit/${val}`,
            });
        },
        getData(val) {
            this.$router.push({
                path: `/homepage_setting/promote_img/add/${val}`,
            });
        },
    },
    async mounted() {
        this.loading = true;
        try {
            const { data } = await this.$api.GetPromoList();
            this.datas = { ...data };
            this.loading = false;
        } catch (err) {
            this.loading = false;
            this.$message({
                type: "error",
                message: "取得資料失敗",
            });
        }
        //     .then((res) => {
        //         return { status: res.status, data: res.data };
        //     })
        //     .catch(() => {
        //         this.$message({
        //             type: "error",
        //             message: "取得資料失敗"
        //         });
        //     })
        //     .finally(() => {
        //         this.loading = false;
        //     });
        // if (status == 200) {
        //     this.datas = { ...data };
        // }
    },
};
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
    tbody {
        tr .show-ellipsis {
            max-width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
</style>
